import React, { memo } from 'react';

import Header from './Header';

import Button from '../../Button';
import Editor from '../../Forms/Edit';
import proptypes from '../../proptypes';

const Edit = ({
  datetime,
  host,
  title,
  showid,
  flyer,
  description,
  artists,
  genres,
  posted,
  disclaimer,
  media,
}) => (
  <div className="col s12 margin-1">
    <h3>Edit Show: </h3>
    <div className="pad ttyl-grey">
      <div className="pad">
        <Header datetime={datetime} host={host} title={title} />
      </div>
    </div>
    <hr />
    <Editor
      id={`${title}-${showid}`}
      title={title}
      names={{
        title,
        host,
        datetime,
        flyer,
        description,
        artists,
        genres,
        posted,
        disclaimer,
        media,
      }}
    />
    <div className="row pad">
      <Button
        id={`EditShow-${showid}`}
        classNames="btn btn-large"
        title="Save Edit(s)"
        label="Save Edit(s)"
        onClick={() => alert('Saving: ' + showid)}
      />
    </div>
  </div>
);

Edit.propTypes = {
  datetime: proptypes.typeCheck.string,
  host: proptypes.typeCheck.string,
  title: proptypes.typeCheck.string,
  showid: proptypes.typeCheck.string,
  flyer: proptypes.typeCheck.string,
  description: proptypes.typeCheck.string,
  artists: proptypes.typeCheck.string,
  genres: proptypes.typeCheck.string,
  posted: proptypes.typeCheck.string,
  disclaimer: proptypes.typeCheck.string,
  media: proptypes.typeCheck.string,
};

Edit.defaultProps = {
  datetime: false,
  host: false,
  title: false,
  artists: false,
  genres: false,
  flyer: false,
  description: false,
  showid: false,
  disclaimer: false,
  posted: false,
  media: false,
};

export default memo(Edit);
