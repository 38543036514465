import React from 'react';

import Header from './Header';

const HeaderBar = ({
  id,
  title,
  level,
  children,
  classNames,
  styles,
}) => (
  <div
    id={`${id}-Wrapper`}
    className={
      classNames || (
        'row left-align black raised margin-2'
      )
    }
    style={styles || {}}
  >
    <Header id={id} title={title} level={level || 4}>{children}</Header>
  </div>
);

export default HeaderBar;
