import React, { memo } from 'react';

import proptypes from '../../proptypes';

import Content from './Content';
import Header from './Header';
import Footer from './Footer';
import Media from './Media';

const Display = ({
  datetime,
  host,
  title,
  artists,
  genres,
  flyer,
  description,
  showid,
  disclaimer,
  posted,
  media,
}) => (
  <div className="col s12 margin-1">
    <div className="center-align">
      <Header datetime={datetime} host={host} title={title} />
      <Content
        title={title}
        artists={artists}
        genres={genres}
        flyer={flyer}
        description={description}
        showid={showid}
      />
      <Footer disclaimer={disclaimer} posted={posted} />
      <Media media={media} />
    </div>
  </div>
);

Display.propTypes = {
  datetime: proptypes.typeCheck.string,
  host: proptypes.typeCheck.string,
  title: proptypes.typeCheck.string,
  artists: proptypes.typeCheck.string,
  genres: proptypes.typeCheck.string,
  flyer: proptypes.typeCheck.string,
  description: proptypes.typeCheck.string,
  showid: proptypes.typeCheck.string,
  disclaimer: proptypes.typeCheck.string,
  posted: proptypes.typeCheck.string,
  media: proptypes.typeCheck.string,
};

Display.defaultProps = {
  datetime: false,
  host: false,
  title: false,
  artists: false,
  genres: false,
  flyer: false,
  description: false,
  showid: false,
  disclaimer: false,
  posted: false,
  media: false,
};

export default memo(Display);
