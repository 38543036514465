import React, { Fragment, memo } from 'react';

import Modal from '../../Modal';
import Text from '../../Text';

const Description = ({ title, description }) => (
  !!description && (
    <div className="col s12 m6" style={{ padding: 0 }}>
      {
        (description.length > 300)
          ?
          <Fragment>
            <div
              className="row ttyl-grey show-desc extended"
              style={{
                padding: '20px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxHeight: '25vh',
                margin: 0,
                zIndex: 0,
              }}
            >
              { description }
            </div>
            <Modal
              id="FullShowDescriptionModal"
              open="Read More"
            >
              { !!title && (
                <div className="col s12 card-title black pad-tb margin-b">
                  <Text
                    classNames="font-size-5"
                    styles={{ lineHeight: '2rem' }}
                  >
                    { title }
                  </Text>
                </div>
              )}
              <div className="col s12 white">
                <p className="card-info black-text" style={{ padding: '15px' }}>
                  { description }
                </p>
              </div>
            </Modal>
          </Fragment>
          :
          <div
            className="row ttyl-grey show-desc"
            style={{
              padding: '20px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxHeight: '25vh',
              margin: 0,
              zIndex: 0,
            }}
          >
            { description }
          </div>
      }
    </div>
  )
);

export default memo(Description);
