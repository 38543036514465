import React, { memo } from 'react';

import Button from '../../Button';

const Purchasing = ({
  showid,
  onClick,
  tickets,
  children,
}) => (
  children || (
    <div className="margin-tb">
      { !!onClick && !!showid && (
        <Button
          classNames="btn green fluid raised"
          title="Get Ticket(s)"
          label="Get Ticket(s)"
          onClick={() => onClick(showid)}
          styles={{ height: '50px' }}
        />
      )}
      { !!tickets && tickets > 0 && (
        <Button
          classNames="btn red fluid raised disabled"
          title="Sold Out"
          label="Sold Out"
          styles={{ height: '50px' }}
        />
      )}
    </div>
  )
);

export default memo(Purchasing);
