import axios from 'axios';

const clientid = 'ttylevents-app';
const isProd = false;

const buildUrl = path => (
  `https://us-central1-ttylevents-app-${
    isProd ? 'prod' : 'dev'
  }.cloudfunctions.net/${path}`
);

const getShows = req => (
  axios.post(buildUrl('getEvents'), {
    clientid,
    ...req,
  }).then(({ data }) => data).catch((err) => err)
);

export default {
  getShows,
};
