import React from 'react';

import proptypes from './proptypes';
import { getRandomNumberBetween } from './utils';
import { assets } from '../../Config';

const getSrc = (src, videoNumber) => (
  src || `${assets}/videos/stock_video_${videoNumber}.mp4`
);

const BackgroundVideo = ({ src }) => {
  const videoNumber = getRandomNumberBetween(1, 2);
  return (
    <div className="background-video-wrapper">
      <div className="background-video-overlay" />
      <video
        playsInline
        autoPlay
        muted
        loop
        id="TTYLBackgroundVideo"
        className={(videoNumber === 1) ? 'top-0' : ''}
      >
        <source
          src={getSrc(src, videoNumber)}
          type="video/mp4"
        />
      </video>
    </div>
  );
};

BackgroundVideo.propTypes = {
  src: proptypes.typeCheck.string,
};

BackgroundVideo.defaultProps = {
  src: false,
};

export default BackgroundVideo;
