import React, { memo } from 'react';

import Anchor from '../Anchor';
import Image from '../Images/Image';
import HeaderBar from '../HeaderBar';
import { typeCheck } from '../proptypes';

const moduleName = 'ttyl-marketing-placement-embed';

const OwnHeader = ({
  headerText,
  href,
  id,
  to,
}) => (
  <div className="col s12 pad-tb margin-tb">
    <Anchor
      href={href}
      to={to}
      id={`HeaderTitleLink-${id}`}
    >
      <HeaderBar
        id={`HeaderTitle-${id}`}
        level={4}
        classNames="row left-align black raised margin-0"
      >
        { headerText }
      </HeaderBar>
    </Anchor>
  </div>
);

const OwnLink = ({ href, linkText, to, classNames }) => (
  (href || to) && (
    <div className={classNames || 'col s12'}>
      <Anchor
        id={href}
        classNames="btn btn-primary btn-large fluid black white-text center-align pad-tb margin-tb"
        textStyles={{
          bottom: '10px',
          position: 'relative',
        }}
        href={href}
        to={to}
        title={linkText || 'Get Tickets Now!'}
      />
    </div>
  )
);

const Placement = ({
  children = null,
  id,
  classNames,
  header,
  headerText,
  linkText,
  to,
  href,
  src,
}) => (
  <div data-module-name={moduleName} className="row pad-b">
    { header ? header : (
      <OwnHeader
        href={href}
        headerText={headerText}
        id={id}
        to={to}
      />
    )}
    <div className="col s12 m6 l5">
      <Anchor href={href} id={`${href}-${id}-${linkText}-${src}`}>
        <Image
          classNames={`${classNames} raised`}
          id={`Image-${id}`}
          src={src}
          alt={`Image ${id}`}
        />
      </Anchor>
    </div>
    <div className="col s12 m6 l7">
      <div className="white-text ttyl-grey raised pad">
        <h5 className="center">{headerText}</h5>
        <p className="center">{children}</p>
        <hr />
        <OwnLink href={href} to={to} classNames="fluid" />
      </div>
    </div>
  </div>
);

Placement.propTypes = {
  id: typeCheck.string.isRequired,
  header: typeCheck.node,
  headerText: typeCheck.string,
  to: typeCheck.string,
  href: typeCheck.string,
  src: typeCheck.string.isRequired,
};

Placement.defaultProps = {
  header: false,
  headerText: false,
  to: false,
  href: false,
};

Placement.displayName = moduleName;

export default memo(Placement);
