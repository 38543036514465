import React, { Fragment } from 'react';
import moment from 'moment';
import SanitizeHtml from 'sanitize-html';

import proptypes from '../proptypes';

import Anchor from '../Anchor';

function extractHostname(url) {
  let hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("//") > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
}

const sanitizeHtmlFully = str => (
  ((str===null) || (str===''))
    ?
    ''
    :
    SanitizeHtml(
      String(str).replace(/<[^>]*>/g, '')
    )
);

const ImageLink = ({ name, src, url }) => (
  <img
    src={src}
    alt={name}
    title={name}
    style={{ width: '100%' }}
  />
);

const ListItemSource = ({ source, author, url }) => (
  <div className="row" style={{ margin: '0 0 5px 0px' }}>
    { source && source.name && url && (
      <div
        className={
          `black raised col ${
            !!author ? 's5' : 's12'
          } secondary-content valign-wrapper white-text`
        }
        style={{ height: '50px' }}
      >
        <div className="fluid center-align">
          { source.name }
        </div>
      </div>
    )}
    { !!author && (
      <div
        className={
          `ttyl-grey raised col ${
            !!source && !!source.name ? 's7' : 's12'
          } secondary-content valign-wrapper white-text`
        }
        style={{ height: '50px' }}
      >
        <div className="fluid center-align">
          { sanitizeHtmlFully(author.replace(/via/g, '')) }
        </div>
      </div>
    )}
  </div>
);

const ListItemTitle = ({ title }) => (
  <div className="row" style={{ margin: 0 }}>
    { title && (
      <div
        className="card-title ttyl-grey raised col s12 margin-0 waves-effect waves-light"
        style={{
          padding: '10px',
          height: '56px',
          maxHeight: '56px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <div className="center-align">
          { title }
        </div>
      </div>
    )}
  </div>
);

const ListItemImage = ({ source, urlToImage, url }) => (
  <div
    className="row pad-0"
    style={{
      margin: '5px 0 0 0',
    }}
  >
    { source && source.name && (
      <div
        className="fluid center-align waves-effect waves-light extended"
        style={{
          height: '200px',
        }}
      >
        { urlToImage
            ?
            <ImageLink name={source.name} src={urlToImage} url={url} />
            :
            (source.name || '')
        }
      </div>
    )}
  </div>
);

const ListItemPublished = ({ publishedAt }) => (
  <div className="black raised center-align pad margin-0">
    Updated: { moment(publishedAt).format('M/D h:mm a') }
  </div>
);

const directToUrl = (url) => {
  alert(`You are leaving TTYLEvents.com. We are directing you to ${extractHostname(url)} . Thanks and come back soon! TTYL!`);
  global.location.href = url;
};

const ListItem = ({
  source,
  author,
  title,
  description,
  url,
  urlToImage,
  publishedAt,
}) => (
  <div className="col s12 m6 l4 news-wrapper">
    <Anchor
      id={`LinkTo-${url}`}
      title={`More on: ${source.name}`}
      classNames="uppercase fluid left-align"
      style={{ margin: '5px 0' }}
      onClick={() => directToUrl(url)}
      target="_blank"
    >
      <div className="margin-b-0">
        { title && url && (
          <Fragment>
            <ListItemSource source={source} author={author} url={url} />
            <ListItemTitle title={title} />
          </Fragment>
        )}
        { source &&
          <ListItemImage source={source} urlToImage={urlToImage} url={url} />
        }
        { description && (
          <div
            className="row ttyl-grey raised font-size-1"
            style={{
              padding: '10px',
              margin: '0 0 5px 0',
              height: '76px',
              maxHeight: '76px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 'normal',
            }}
          >
            { description }
          </div>
        )}
      </div>
      { publishedAt && (
        <ListItemPublished publishedAt={publishedAt} />
      )}
    </Anchor>
  </div>
);

ListItem.displayName = 'NewsListItem';

ListItem.propTypes = {
  source: proptypes.typeCheck.obj,
  author: proptypes.typeCheck.string,
  title: proptypes.typeCheck.string,
  description: proptypes.typeCheck.string,
  url: proptypes.typeCheck.string,
  urlToImage: proptypes.typeCheck.string,
  publishedAt: proptypes.typeCheck.string,
  content: proptypes.typeCheck.string,
};

ListItem.defaultProps = {
  source: false,
  author: false,
  title: false,
  description: false,
  url: false,
  urlToImage: false,
  publishedAt: false,
  content: false,
};

export default ListItem;
