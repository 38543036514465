import React, { memo } from 'react';

const Flyer = ({ title, flyer }) => (
  !!flyer && (
    <div className="col s12 m6" style={{ padding: 0 }}>
      <div className="row" style={{ margin: '0 0 5px 0' }}>
        <img
          id={`${title} Flyer`}
          className="raised"
          alt={`${title} Flyer`}
          src={flyer}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  )
);

export default memo(Flyer);
