import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import ls from 'local-storage';

import Button from './components/Button';
import HeaderBar from './components/HeaderBar';
import Icon from './components/Icon';
import Preloader from './components/Preloader';
import ShowsComponent from './components/Shows';

import SHOWS from '../Functions/Shows';

const { getShows } = SHOWS;

const TWO_MIN = 2*60*1000;

const COMPONENT_NAME = 'Shows';

const ShowsLoader = ({ id, title }) => (
  <Preloader title={id ? false : title} isFixed={false} />
);

const getPrevPath = prevPath => (
  prevPath ? (
    prevPath === '/' ? '/Home' : `to ${prevPath}`
  ) : '/Home'
);

const ShowsDataLayer = ({
  // data props
  id,
  shows,
  pastShows,
  loading,
  auth,
  user,
  header,
  // history props
  history,
  prevPath,
  // functions props
  refreshShows,
}) => (
  <section id={`${COMPONENT_NAME}-DataLayer`} className={`margin-tb ${id ? 'pad-lr' : ''}`}>
    {/* Shows Listing Component */}
    {(!loading && !id && header && (shows.length > 0)) && (
      <HeaderBar
        id="UpcomingShowsHeader"
        classNames="row left-align black raised margin-tb-2 margin-lr-0"
        level={4}
      >
        Upcoming Shows
        <Button title="Refresh Shows" styles={{ float: 'right', color: 'white' }} onClick={refreshShows}>
          <Icon icon="autorenew" />
          <div className="pad-l right hide-on-small-only">
            Refresh Shows
          </div>
        </Button>
      </HeaderBar>
    )}
    {!id && (
      <div className="row uppercase">
        {loading ? <ShowsLoader id={id} title="Upcoming Shows" /> : (
          <ShowsComponent
            showid={id}
            shows={shows}
            auth={auth}
            user={user}
          />
        )}
      </div>
    )}
    {(!loading && !id && header && (pastShows.length > 0)) && (
      <HeaderBar
        id="PastShowsHeader"
        classNames="row left-align black raised margin-tb-2 margin-lr-0"
        level={4}
      >
        Past Shows
      </HeaderBar>
    )}
    {!id && (
      <div className="row uppercase">
        {loading ? <ShowsLoader id={id} title="Past Shows" /> : (
          <ShowsComponent
            showid={id}
            pastShows={pastShows}
            auth={auth}
            user={user}
          />
        )}
      </div>
    )}
    {/* Individual Show Component */}
    {id && (
      <Fragment>
        {!loading && (
          <div className="row left">
            <Button
              onClick={history.goBack}
              label="Go Back"
              title="Go Back"
              classNames="btn btn-small"
            >
              <Icon icon="chevron_left" />
            </Button>
            <Button
              onClick={history.goBack}
              label="Go Back"
              title="Go Back"
              classNames="margin-l btn btn-small"
            >
              <span className="white-text pad">
                Go Back {
                  !!prevPath
                  && prevPath.length > 0
                  && getPrevPath(prevPath)
                }
              </span>
            </Button>
          </div>
        )}
        <div className="row uppercase">
          {loading ? <ShowsLoader id={id} title="Shows" /> : (
            <ShowsComponent
              showid={id}
              pastShows={pastShows}
              auth={auth}
              user={user}
            />
          )}
        </div>
      </Fragment>
    )}
  </section>
);

const buildReq = id => ({
  action: 'findBy',
  params: {
    where: 'showid',
    which: id,
  },
});

const orderByDate = list => (
  list.length > 0 ? (
    list.sort((a, b) => (new Date(a.datetime) < new Date(b.datetime)) ? 1 : -1)
  ) : []
);


class TheShows extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: false,
      auth: false,
      shows: [],
      loading: true,
      user: false,
    };
  }

  componentDidMount = () => this.load(this.props);
  
  componentWillUnmount = () => {};

  findShows = id => id ? getShows(buildReq(id)) : getShows();

  show = () => setTimeout(() => (
    this.setState({ loading: false })
  ), 100);

  load = ({ id, auth, user, view }) => {
    const tsNow = new Date();
    const dataSetName = (!!view && view !== '/') ? view : 'shows';
    const lsShows = JSON.parse(ls.get(dataSetName));
    const ts = (
      (!!lsShows && !!lsShows.ts) ? lsShows.ts : false
    );
    const oldPastShows = (
      (!!lsShows && !!lsShows.pastShows) ? lsShows.pastShows : false
    );
    const oldNewShows = (
      (!!lsShows && !!lsShows.newShows) ? lsShows.newShows : false
    );

    const refreshShows = () => (
      this.setState({ loading: true }, 
        () => this.findShows(id).then((shows) => {
          const newShows = [];
          const pastShows = [];
          if (shows.length > 0) {
            shows.forEach((show) => {
              const now = new Date().setHours(0, 0, 0, 0);
              const eventTime = new Date(show.datetime).setHours(0, 0, 0, 0);
              return (
                (eventTime > now || eventTime === now)
                  ? newShows.push(show) : pastShows.push(show)
              );
            });
            ls.set(
              dataSetName,
              JSON.stringify({ ts: Date.now(), pastShows, newShows })
            );
          }
          this.setState({
            id,
            auth,
            user,
            shows: orderByDate(newShows).reverse(),
            pastShows: orderByDate(pastShows),
          }, this.show);
        })
      )
    );

    if(!ts || ((tsNow - ts) > TWO_MIN)) {
      refreshShows();
    } else {
      this.setState({
        id,
        auth,
        user,
        shows: orderByDate(oldNewShows).reverse(),
        pastShows: orderByDate(oldPastShows),
        refreshShows,
      }, this.show);
    }
  };

  render = () => (<ShowsDataLayer { ...this.state } { ...this.props} />);
}

TheShows.displayName = `${COMPONENT_NAME}-Provider`;

const mapStateToProps = (state, { id, auth, user, header, view }) => ({
  id, auth, user, header, view,
});

export default connect(mapStateToProps)(TheShows);
