import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import FUNCTIONS from './Functions';

import Navigation from './Modules/Navigation';
import Home from './Modules/Home';
import Shows from './Modules/Shows';
import Contact from './Modules/Contact';
import Footer from './Modules/Footer';
// import NotFound from './Modules/NotFound';
import News from './Modules/News';
import Terms from './Modules/Terms';

import Preloader from './Modules/components/Preloader';
import Subscribe from './Modules/components/Mailchimp/Subscribe';
import Promote from './Modules/components/Mailchimp/Promote';

const { NAV } = FUNCTIONS;

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      auth: false,
      FUNCTIONS,
      prevPath: '',
    };
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      window.scrollTo(0, 0);
      NAV.hideSideNav();
      this.setState({ prevPath: this.props.location.pathname });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const { history } = this.props;
    const { prevPath } = this.state;
    return (
      <Fragment>
        <Navigation
          history={{
            location: history.location,
            action: history.action,
          }}
          auth={this.state.auth}
        />
        <Subscribe />
        <Promote />
        <Preloader id="AppLoader" />
        <div
          id="root-body"
          className="container white-text pad"
          style={{ display: 'none'}}
        >
          {
            /**
            *  route to diffrent component
            */
          }
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Home view={history.location.pathname} />}
              state={this.state}
            />
            <Route
              exact
              path="/news"
              component={() => (
                <News view={history.location.pathname} />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/shows"
              component={() => (
                <Shows header={true} view={history.location.pathname} />
              )}
              state={this.state}
            />
            <Route
              path="/show/:id"
              component={({ match }) => (
                <Shows
                  auth={this.state.auth}
                  view={history.location.pathname}
                  id={
                    (!!match && !!match.params && !!match.params.id)
                      ?
                      match.params.id
                      :
                      false
                  }
                  history={history}
                  prevPath={prevPath}
                />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/Contact"
              component={() => (
                <Contact
                  auth={this.state.auth}
                  view={history.location.pathname}
                />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/terms"
              component={() => (
                <Terms
                  auth={this.state.auth}
                  view={history.location.pathname}
                />
              )}
              state={this.state}
            />
            <Route
              component={() => (
                <Redirect to="/" />
              )}
              state={this.state}
            />
            <Redirect to="/" />
          </Switch>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

App.propTypes = {
  history: PropTypes.shape({
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    action: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

App.defaultProps = {
  location: {},
};

export default withRouter(App);
