import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import ownPropTypes from './proptypes';

import Icon from './Icon'

class Tabs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { selected: false };
  }

  componentDidMount() {
    const { tabs } = this.props;
    const { selected } = this.state;
    if (!!global.$) {
      global.$('ul.tabs').tabs();
      if (!selected) {
        this.setState({ selected: tabs[0].title });
      }
    }
  }

  // componentWillUnmount() {
  //   // if (!!global.$) {
  //   //   global.$('ul.tabs').tabs();
  //   //   this.setState({
  //   //     tabs: this.props.tabs.map(tab => tab),
  //   //     loading: false,
  //   //   });
  //   // }
  // }

  showContent = id => this.setState({
    selected: id,
  });

  isShown = ({ title }, selected) => (title === selected ? '' : 'hide');

  render() {
    const { id, classNames, styles, tabs } = this.props;
    const { selected } = this.state;
    return(
      <div id={id} className="row"  style={{ margin: '0', ...styles.container}}>
        <div
          className={`col s12 ${classNames}`}
          style={{ padding: '0', ...styles.tabs }}
        >
          <ul className="tabs" style={styles.tabsList}>
            { !!tabs && tabs.map((tab, index) => (
              <li
                className={
                  `tab col ${tab.classNames} ${
                    tab.disabled ? 'disabled' : ''
                  } ${
                    selected === tab.title ? 'active grey darken-1' : 'grey darken-4'
                  }`
                }
                key={`${tab.title.replace(/\s/g, '')}-{index}-tab`}
              >
                <a
                  id={`#${tab.title.replace(/\s/g, '')}-content`}
                  href={`#${tab.title.replace(/\s/g, '')}-content`}
                  onClick={() => this.showContent(tab.title)}
                  style={{ cursor: 'pointer' }}
                  className={`waves-effect waves-light ${tabs.innerClassNames}`}
                >
                  {
                    tab.icon
                      ? (
                        <Fragment>
                          <span className="hide-on-small-only">
                            {tab.header || tab.title}
                          </span>
                          <span className="hide-on-med-and-up">
                            <Icon icon={tab.icon} />
                          </span>
                        </Fragment>
                      ) : (
                        <span>
                          {tab.header || tab.title}
                        </span>
                      )
                  }
                </a>
              </li>
            ))}
          </ul>
        </div>
        { !!tabs && tabs.map((tab) => (
          <div
            id={`#${tab.title.replace(/\s/g, '')}`}
            className={`col s12 ${this.isShown(tab, selected)}`}
            style={
              styles.childContainer || { margin: 0, padding: 0 }
            }
            key={`${tab.title.replace(/\s/g, '')}-{index}-content`}
          >
            {tab.child}
          </div>
        ))}
      </div>
    );
  }
}

Tabs.propTypes = {
  id: ownPropTypes.typeCheck.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: ownPropTypes.typeCheck.string,
      header: ownPropTypes.typeCheck.children,
      child: ownPropTypes.typeCheck.children,
      disabled: PropTypes.bool,
    })
  ),
  styles: PropTypes.shape({}),
};

Tabs.defaultProps = {
  tabs: false,
  styles: {},
};

export default Tabs;
