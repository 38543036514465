import React, { memo } from 'react';

import Header from './Header';

import Button from '../../Button';

const Delete = ({
  datetime,
  host,
  title,
  showid,
}) => (
  <div className="col s12 ttyl-grey">
    <h3>Delete Show: </h3>
    <div className="pad ttyl-grey">
      <div className="pad">
        <Header datetime={datetime} host={host} title={title} />
      </div>
    </div>
    <div className="row pad">
      <p>Once you have completed this, you will have 48 hours to decide if you would like to resurrect the Show, before it gets completely deleted.</p>
      <Button
        id={`DeleteShow-${showid}`}
        classNames="btn btn-large"
        title="Delete"
        label="Delete"
        onClick={() => alert('Deleting: ' + showid)}
      />
    </div>
  </div>
);

export default memo(Delete);
