import React, { memo } from 'react';

import './Embed.css';

const ttylSrc = "https://storage.googleapis.com/ttylevents-cdn/events/jp_at_pops_2019/jp-final-15.mp4";

const Embed = ({ id, src }) => (
  <div id={id} className="embed-container">
    <iframe
      id={`Iframe${id}`}
      title={`Iframe ${id}`}
      src={src || ttylSrc}
      style={{ border: 0 }}
    />
  </div>
);

export default memo(Embed);
