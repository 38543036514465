import NEWS from './News';
import SHOWS from './Shows';

const initializeSideNav = (e) => {
  if (global.$) {
    if (global.$('.button-collapse')) {
      setTimeout(() => {
        global.$('.button-collapse').sideNav('hide');
      }, 250);
    } else {
      setTimeout(() => {
        global.$('.button-collapse').sideNav();
      }, 250);
    }
  }
  if (e) {
    e.preventDefault();
  }
};

export default {
  NEWS,
  SHOWS,
  NAV: {
    hideSideNav: initializeSideNav,
  },
};
