import React, { PureComponent } from 'react';

const COMPONENT_NAME = 'Accordion';

const List = ({
  items,
  id,
  classNames,
  open,
}) => (
  items.map((
    {
      title,
      icon,
      header,
      child,
      content,
      selected,
      disabled,
    }, index
  ) => (
    !disabled && (
      <li key={`${JSON.stringify(items)}-${id}-${index}`}>
        <div
          className={
            `ttyl-grey raised border-0 waves-effect waves-light ${
              open ? 'pad valign-wrapper fluid' : 'collapsible-header'
            } pad-1`
          }
        >
          { header || child }
        </div>
        { header && (
          <div
            className={`${open ? 'fluid' : 'collapsible-body'} grey darken-4 pad-1`}
            style={{ minHeight: '75px' }}
          >
            { child || (
              <p>{ content || 'Lorem ipsum dolor sit amet.'}</p>
            )}
          </div>
        )}
      </li>
    )
  ))
);

class Accordion extends PureComponent {
  // state = {
  //   shown: [],
  // }
  //
  // toggle = index => (
  //   this.setState({ shown: [index]: !this.state.shown[index] })
  // )

  componentDidMount() {
    if (global.$) {
      global.$('.collapsible').collapsible();
    }
  }

  render() {
    const { items, id, classNames, open, styles } = this.props;
    return (
      <div style={styles}>
        <ul
          data-module-name={COMPONENT_NAME}
          className={open ? '' : 'collapsible'}
          data-collapsible={open ? '' : 'accordion'}
        >
          { !!items && (
            <List
              items={items}
              id={id}
              classNames={classNames}
              open={open}
            />
          )}
        </ul>
      </div>
    );
  }
}

Accordion.displayName = COMPONENT_NAME

export default Accordion;
