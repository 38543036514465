import React, { memo } from 'react';

import './Loader.css';

const ttylLogo = 'https://storage.googleapis.com/ttylevents-cdn/assets/ttyl_logo_white.png';

const getStyles = isFixed => (
  isFixed ? { position: 'absolute', top: 0, marginTop: 0 } : {}
);

const Loader = ({
  id,
  logo,
  title,
  isFixed = true,
}) => (
  <div id={id} className={`loader-layover ${isFixed ? 'loader-fixed' : 'loader-relative'} raised`}>
    <div
      className="progress"
      style={{ ...getStyles(isFixed) }}
    >
      <div className="indeterminate"></div>
    </div>
		<div className="loader-layover-wrapper pad">
			<img
        className="loader-icon pulse"
        alt="TTYL Content Loader"
        src={logo || ttylLogo}
      />
      {title && (
        <div className="flow-text center lowercase">
          <p>{title}</p>
        </div>
      )}
      {!title && (
        <div className="loader-volume">
          <div className="lds-facebook">
  					<div></div>
  					<div></div>
  					<div></div>
  				</div>
  			</div>
      )}
		</div>
    {title && (
      <div className="progress">
        <div className="indeterminate"></div>
      </div>
    )}
	</div>
);

export default memo(Loader);
