import React, { memo } from 'react';
import moment from 'moment';

import Text from '../../Text';

const Footer = ({ disclaimer, posted }) => (
  (disclaimer || posted) && (
    <div className="row ttyl-grey raised">
      { !!disclaimer && (
        <div
          className="col s12 white-text"
          style={{ padding: '10px' }}
        >
          <Text>
            { disclaimer }
          </Text>
        </div>
      )}
      { !!posted && (
        <div
          className="col s12 secondary-content"
          style={{ padding: '10px' }}
        >
          <Text>
            Posted: { moment(posted).format('MMMM Do YYYY, h:mm:ss a') }
          </Text>
        </div>
      )}
    </div>
  )
);

export default memo(Footer);
