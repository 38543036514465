import PropTypes from 'prop-types';

export const typeCheck = {
  bool: PropTypes.bool,
  string: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  obj: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.bool,
  ]),
  arrOfObj: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.bool,
  ]),
  arrOfStr: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.bool,
  ]),
  arrOfNum: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.bool,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.bool,
  ]),
  node: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.bool,
  ]),
};

export default {
  typeCheck,
};
