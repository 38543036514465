import React, { memo } from 'react';
import moment from 'moment';

import Text from '../../Text';
import proptypes from '../../proptypes';

const Header = ({
  datetime,
  host,
  title,
}) => (
  <div className="row" style={{ marginBottom: '10px' }}>
    <div className="col s12">
      <div className="row">
        { !!datetime && (
          <div
            className="col s4 black white-text center-align raised show-date"
            style={{
              paddingTop: '2px',
            }}
          >
            <div className="row" style={{ margin: '0' }}>
              <div className="col s12">
                { moment(datetime).format('MMMM') }
              </div>
              <div className="col s12 font-size-3">
                { moment(datetime).format('DD') }
              </div>
            </div>
          </div>
        )}
        { !!host && (
          <div className="col s8 black valign-wrapper raised show-host">
            <div className="fluid center-align">
              <Text classNames="card-title font-size-2">
                { host }
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
    { !!title && (
      <div className="col s12 card-title ttyl-grey raised pad-tb">
        <Text classNames="font-size-3" styles={{ lineHeight: '2rem' }}>
          { title }
        </Text>
      </div>
    )}
  </div>
);

Header.propTypes = {
  datetime: proptypes.typeCheck.string,
  host: proptypes.typeCheck.string,
  title: proptypes.typeCheck.string,
};

Header.defaultProps = {
  datetime: false,
  host: false,
  title: false,
};

export default memo(Header);
