import React, { memo } from 'react';

import Delete from './Delete';
import Edit from './Edit';

import Tabs from '../../Tabs';

import proptypes from '../../proptypes';
import Display from './Display';

const COMPONENT_NAME = 'FullListItem';

const FullItem = ({
  // Item Data
  showid,
  host,
  title,
  flyer,
  artists,
  genres,
  datetime,
  posted,
  description,
  disclaimer,
  media,
  //
  accountid,
}) => {
  const ownTabs = [
    {
      icon: 'book',
      title: 'Details',
      classNames: 's12 m4',
      child: (
        <Display
          datetime={datetime}
          host={host}
          title={title}
          artists={artists}
          genres={genres}
          flyer={flyer}
          description={description}
          showid={showid}
          disclaimer={disclaimer}
          posted={posted}
          media={media}
        />
      ),
      disabled: false,
      selected: true,
    },
    {
      title: 'Edit',
      icon: 'edit',
      classNames: 's12 m4',
      child: (
        <Edit
          datetime={datetime}
          host={host}
          title={title}
          showid={showid}
          flyer={flyer}
          description={description}
          artists={artists}
          genres={genres}
          posted={posted}
          disclaimer={disclaimer}
          media={media}
        />
      ),
      disabled: false,
    },
    {
      title: 'Delete',
      icon: 'delete',
      classNames: 's12 m4',
      child: (
        <Delete
          datetime={datetime}
          host={host}
          title={title}
          showid={showid}
        />
      ),
      disabled: false,
    },
    // {
    //   icons: 'add'
    // }
  ];

  return (
    accountid ? <Tabs id={`edit-${showid}`} tabs={ownTabs} /> : (
      <Display
        datetime={datetime}
        host={host}
        title={title}
        artists={artists}
        genres={genres}
        flyer={flyer}
        description={description}
        showid={showid}
        disclaimer={disclaimer}
        posted={posted}
        media={media}
      />
    )
  );
};

FullItem.displayName = COMPONENT_NAME;

FullItem.propTypes = {
  accountid: proptypes.typeCheck.string,
  showid: proptypes.typeCheck.string,
  genres: proptypes.typeCheck.string,
  host: proptypes.typeCheck.string,
  title: proptypes.typeCheck.string,
  flyer: proptypes.typeCheck.string,
  artists: proptypes.typeCheck.arrOfStr,
  datetime: proptypes.typeCheck.string,
  posted: proptypes.typeCheck.obj,
  tickets: proptypes.typeCheck.obj,
  vip: proptypes.typeCheck.arrOfObj,
  description: proptypes.typeCheck.string,
  disclaimer: proptypes.typeCheck.string,
  purchase: proptypes.typeCheck.obj,
  media: proptypes.typeCheck.arrOfObj,
};

FullItem.defaultProps = {
  accountid: false,
  showid: false,
  genres: false,
  host: false,
  title: false,
  flyer: false,
  artists: false,
  datetime: false,
  posted: false,
  tickets: false,
  vip: false,
  description: false,
  disclaimer: false,
  purchase: false,
  media: false,
};

export default memo(FullItem);
