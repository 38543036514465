import React, { memo } from 'react';

import Description from './Description';
import Flyer from './Flyer';
import Purchasing from './Purchasing';
import Tags from './Tags';

const Content = ({
  title,
  artists,
  genres,
  flyer,
  description,
  purchase,
  showid,
}) => (
  <div className="row" style={{ marginBottom: '10px' }}>
    <Purchasing {...purchase} title={title} showid={showid} />
    <Tags genres={genres} artists={artists} />
    <Flyer title={title} artists={artists} genres={genres} flyer={flyer} />
    <Description
      title={title}
      description={description}
      purchase={purchase}
      showid={showid}
    />
    <Purchasing {...purchase} title={title} showid={showid} />
  </div>
);

export default memo(Content);
