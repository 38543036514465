import React, { Fragment } from 'react';
import proptypes from '../../proptypes';

const Tags = ({
  artists,
  genres,
}) => (
  <Fragment>
    <div className="row" style={{ margin: '10px' }}>
      { !!artists && artists.length > 0 && (
        artists.map(artist => (
          <div
            className="chip ttyl-grey white-text raised"
            key={JSON.stringify(artist)}
          >
            {artist}
          </div>
        ))
      )}
    </div>
    <div className="row" style={{ margin: '10px' }}>
      { !!genres && genres.length > 0 && (
        genres.map(genre => (
          <div className="chip raised" key={JSON.stringify(genre)}>
            {genre}
          </div>
        ))
      )}
    </div>
  </Fragment>
);

Tags.propTypes = {
  artists: proptypes.typeCheck.arrOfStr,
  genres: proptypes.typeCheck.arrOfStr,
};

Tags.defaultProps = {
  artists: false,
  genres: false,
};

export default Tags;
