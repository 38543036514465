import React, { memo } from 'react';

import Placement from './components/Placements';
import Slider from './components/Slider';

import CONFIG from '../Config';

const { ASSETS } = CONFIG;

const { DARK_LOGO } = ASSETS;

const slides = [
  // TTYL Events Intro
  {
    caption: 'Welcome to TTYL Events',
    subCaption: 'The Superior Events Collective | Creative, Managing, Marketing, All Events',
    src: DARK_LOGO,
  },
  // Tropical Beach - La Marina 2018
  {
    caption: 'TTYL Events and Deep Roots Presents: Tropical Beach 2018',
    subCaption: 'At La Marina, NY',
    src: 'https://storage.googleapis.com/ttylevents-cdn/events/Tropical%20Beach%20-%20La%20Marina%20-%202018/MVI_8508.jpg',
  },
  {
    caption: 'TTYL Events and Deep Roots Presents: Tropical Beach 2018',
    subCaption: 'At La Marina, NY',
    src: 'https://storage.googleapis.com/ttylevents-cdn/events/Tropical%20Beach%20-%20La%20Marina%20-%202018/MVI_8470.jpg',
  },
  // Labor Day - Club Oasis 2018
  {
    caption: 'TTYL Events Presents: Labor Day 2018',
    subCaption: 'At Club Oasis, NJ',
    src: 'https://storage.googleapis.com/ttylevents-cdn/events/Labor%20Day%20-%20Club%20Oasis%20-%202018/DSC_0952.jpg',
  },
  {
    caption: 'TTYL Events Presents: Labor Day 2018',
    subCaption: 'At Club Oasis, NJ',
    src: 'https://storage.googleapis.com/ttylevents-cdn/events/Labor%20Day%20-%20Club%20Oasis%20-%202018/DSC_1022.jpg',
  },
];

const Start = ({
  id,
  type = 'slider',
  children = null,
}) => (
  <section id="Start" className="margin-b-2">
    <div className="row margin-t-2">
      <div className="col s12">
        {type === 'slider' && (
          <div className="fluid pad-b ttyl-grey">
            <Slider
              id="TTYLStartSlider"
              slides={slides}
            />
          </div>
        )}
        {type === 'placement' && (
          <Placement
            id={`Placement-${id}`}
            classNames="responsive-img fluid"
            headerText="All About House starts the year off with Bellacour!"
            href="https://www.eventbrite.com/e/all-about-house-featuring-bellecour-tickets-93186883477?aff=TTYLEvents"
            src="https://storage.googleapis.com/ttylevents-cdn/events/2020Bellacour228/ttyl-bellecour-sq.jpg"
          >
            {children}
          </Placement>
        )}
      </div>
    </div>
  </section>
);

export default memo(Start);
