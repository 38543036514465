import React, { memo } from 'react';
import Img from 'react-image';

import Preloader from '../Preloader';

const ttylLogo = (
  'https://storage.googleapis.com/ttylevents-cdn/assets/ttyl_logo_white.png'
);

const Image = ({
  classNames = 'responsive-img',
  alt = 'Image',
  title = 'Image',
  src = false,
  styles = {},
}) => (
  src && (
    <Img
      className={classNames}
      alt={alt}
      title={title}
      src={[src, ttylLogo]}
      style={styles}
      loader={<Preloader isFixed={false} />}
    />
  )
);

export default memo(Image);
