import React, { Fragment, PureComponent } from 'react';
import Icon from '../Icon';

class Edit extends PureComponent {
  constructor(props) {
    super(props);
    const { names } = this.props;
    this.state = { values: names };
  }

  componentDidMount = () => {
    const { names } = this.props;
    if (!!names) {
      this.setState({ values: names });
    }
  }
  
  onChange = e => this.setState({
    values: { [e.target.name]: e.target.value },
  });
  
  render() {
    const { id, title, names } = this.props;
    const nameKeys = Object.keys(names);
    return (
      <div id={id} data-module-name={`${id}-${title}`}>
        <div className="row">
          {!!nameKeys && nameKeys.length > 0 && nameKeys.map((name) => (
            (!!name && name.length > 0 && !!names[name] && names[name].length > 0) && (
              <Fragment key={name}>
                {typeof names[name] === 'string' && names[name].length < 50 && (
                  <form
                    key={JSON.stringify({ id, title, name })}
                    className="row margin-lr"
                  >
                    <h4>{name}</h4>
                    <div className="input-field">
                      <Icon icon="play_arrow" classNames="material-icons prefix" />
                      <input
                        id="icon_prefix"
                        type="text"
                        name={name}
                        onChange={this.onChange}
                        value={this.state.values[name]}
                      />
                      <label htmlFor="icon_prefix">{name}</label>
                    </div>
                  </form>
                )}
                {typeof names[name] === 'string' && names[name].length > 50 && (
                  <form
                    key={JSON.stringify({ id, title, name })}
                    className="row margin-lr"
                  >
                    <h4>{name}</h4>
                    <div className="input-field">
                      <textarea
                        id="textarea1"
                        className="materialize-textarea"
                        name={name}
                        onChange={this.onChange}
                        value={this.state.values[name]}
                      />
                      <label htmlFor={name}>{name}</label>
                    </div>
                  </form>
                )}
                {
                  !!name && name.length > 0 && typeof names[name] === 'object' &&
                  (names[name].length > 0) && (
                    <div className="row margin-lr">
                      <h4>{name}</h4>
                      {names[name].map((arrayItem, index) => (
                        <form
                          key={JSON.stringify({ id, title, name, arrayItem })}
                          className="col s12 m6 l3"
                        >
                          <div className="input-field">
                            <input
                              id="icon_prefix"
                              type="text"
                              name={name}
                              onChange={this.onChange}
                              value={!!this.state.values[name] && this.state.values[name][index]}
                            />
                            <label htmlFor="icon_prefix">{name}</label>
                          </div>
                        </form>
                      ))}
                    </div>
                  )
                }
                {!!name && (name.length > 0) && <hr />}
              </Fragment>
            )
          ))}
        </div>
      </div>
    );
  };
}

export default Edit;