import React, { PureComponent } from 'react';

import Slide from './Slide';

class Slider extends PureComponent {
  componentDidMount() {
    global.$('.slider').slider();
  }

  render() {
    const {
      id,
      slides,
      styles,
      slidesStyles,
      slideStyles,
    } = this.props;

    return (
      <div id={id} className="slider" style={styles}>
        <ul className="slides" style={slidesStyles}>
          {
            slides.map(({
              src,
              caption,
              subCaption,
              children,
              href,
              to,
              linkText,
            }) => (
              <li
                style={slideStyles}
                key={
                  JSON.stringify({
                    src,
                    caption,
                    subCaption,
                    children,
                    href,
                    to,
                    linkText,
                  })
                }
              >
                <Slide
                  src={src}
                  caption={caption}
                  subCaption={subCaption}
                  href={href}
                  to={to}
                  linkText={linkText}
                >
                  { children }
                </Slide>
              </li>
            ))
          }
        </ul>
      </div>
    );
  }
}

export default Slider;
