import React from 'react';

import Accordion from './components/Accordion';
import Anchor from './components/Anchor';
import HeaderBar from './components/HeaderBar';
import Icon from './components/Icon';

const items = [
  {
    header: false,
    child: (
      <p className="pad">
        {`
          These terms and conditions are a binding contract between yourself and
          TTYL Events LLC . AGREEMENT OF THESE TERMS IS EQUIVALENT TO A DIGITAL
          SIGNATURE ON A CONTRACT. ALL SALES ARE FINAL. CANCELLATION OF AN ORDER
          IS NOT PERMITTED AFTER THE ORDER IS PROCESSED. If the event is
          cancelled you will receive a refund minus the Convenience charge WHICH
          IS NEVER REFUNDABLE. If you encounter any issues at the event, you
          must contact info@ttylevents.com within 5 business days following the
          originally scheduled date via a trouble ticket inquiry at in order to
          request a refund and failure to do so will waive your right to a
          refund. Not all refund requests will be honored. YOU CAN NOT EXCHANGE
          ITEMS OF YOUR ORDER PURCHASED FOR OTHER ITEMS FROM THE WEBSITE. YOU
          CAN NOT EXCHANGE ITEMS OF YOUR ORDER PURCHASED FOR MONEY TO USE AS
          CREDIT ON THE WEBSITE. REGARDLESS OF SITUATIONS SUCH AS INCLEMENT
          WEATHER, NATURAL DISASTERS, EXTENDED WAITS IN LINE AT THE VENUE,
          FAMILY EMERGENCIES AND MEDICAL EMERGENCIES A REFUND WILL NOT BE
          ISSUED. All Event personnel have the right to refuse entry to any
          ticket holder, without a refund, for any reason at their discretion.
          Venue and Talent are subject to change without notice and are not
          grounds for a refund. Anything promised by the promoter that is
          unfulfilled is not guaranteed by TTYL Events LLC and is not grounds
          for a refund.
        `}
      </p>
    ),
  },
  {
    header: (
      <h5>
        <Icon icon="chevron_right" classNames="margin-r font-size-2" />
        Willcall
      </h5>
    ),
    child: (
      <p className="pad">
        {`
        Willcall tickets must be picked up only on the day of the event with
        the original credit card used to purchase the tickets and a state
        issued picture ID. All Willcall tickets must be picked up at the same
        time. Not being able to pick up your tickets from Willcall because you
        do not have the above items is not grounds for a refund.
        `}
      </p>
    ),
  },
  {
    header: (
      <h5>
        <Icon icon="chevron_right" classNames="margin-r font-size-2" />
        Shipping
      </h5>
    ),
    child: (
      <p className="pad">
        {`
          Shipping fees are nonrefundable. Tickets can not be shipped to a PO Box
          address. Allow up to 5 business days for delivery for United States
          orders. Allow up to 8 business days for Canadian orders. If you enter
          an undeliverable address you will not receive a refund. Hence, if the
          package needs to be resent additional shipping fees will apply.
          Delivery can only be shipped to the billing address.
        `}
      </p>
    ),
  },
  {
    header: (
      <h5>
        <Icon icon="chevron_right" classNames="margin-r font-size-2" />
        Convenience Fee
      </h5>
    ),
    child: (
      <p className="pad">
        {`
          A non-refundable Convenience fee will be charged TTYL Events LLC and
          will appear on your merchant credit card statement along with your
          order charges. Total amount is shown at checkout. By agreeing to these
          terms you signify that you have understand and agree to this
          Convenience fee.

          In most situations, a Convenience fee is always added to the price of
          the ticket. We make every effort to keep this Convenience charge as
          low as possible. This fee covers the overhead charges involved in box
          office operations not limited to ticketing software fees, credit card
          fees, labor, ticket stock, phone fees and more. Sometimes the
          Convenience fees charged are directed to us by the promoter, and
          sometimes includes fees all ticketing outlets are forced to charge
          for a show, such as venue facility fees, parking fees, etc. Thank you
          for supporting our independent business and understanding that this
          small fee helps to keep us in business.
          `}
      </p>
    ),
  },
  {
    child: (
      <Anchor
        id="ContactUsNow"
        classNames="btn-primary btn-large black fluid"
        title="Contact Us"
        to="contact"
      />
    )
  }
]

const Terms = () => (
  <section id="TermsWrapper" className="margin-tb-2">
    <HeaderBar
      id="TermsAndConditionsHeader"
      level={4}
      classNames="row left-align black raised margin-0"
    >
      Terms and Conditions
    </HeaderBar>
    <div className="margin-tb-2">
      <Accordion id="TermsAndConditions" items={items} />
    </div>
  </section>
);

export default Terms;
