import React, { memo } from 'react';

import Start from './Start';
import Shows from './Shows';
import News from './News';

const Home = ({ view }) => (
  <section id="Home">
    <Start id="HomePage" type="placement">
      Supported by: Kitty Pack | Bxrk | Tekbeatz <br />
      Mob Dad | Bryan Mcelwain <br />
      More Info & VIP Reservations: <br />
      Email: info@ttylevents.com <br />
    </Start>
    <News view={view} />
    <Shows header={true} view={view} />
  </section>
);

Home.displayName = 'Home';

export default memo(Home);
