import React from 'react';

import Images from '../../Images';
import Video from '../../Embed';

const { Image } = Images;

const Media = ({ media }) => (
  !!media && (media.length > 0) && (
    <div className="row ttyl-grey raised">
      { media.map(({ type, src, alt, title }) => (
        <div
          className="col s12 m6 l4"
          key={JSON.stringify({ type, title, alt, src })}
        >
          {(type === 'image') && (
            <Image src={src} alt={alt || title} title={title || alt} />
          )}
          {(type === 'video') && (
            <Video src={src} alt={alt || title} title={title || alt} />
          )}
        </div>
      ))}
    </div>
  )
);

export default Media;
