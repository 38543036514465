import React, { Fragment, memo } from 'react';

import Anchor from '../Anchor';
import Image from '../Images/Image';

const Slide = ({
  src,
  caption,
  subCaption,
  href,
  to,
  children,
  linkText,
}) => (
  <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
    <Image alt={`${caption} image`} src={src} />
    <div className="caption center-align pad-1 ttyl-grey">
      {
        children ? children : (
          <Fragment>
            { caption && <h3>{ caption }</h3>}
            { subCaption && (
              <h5 className="light grey-text text-lighten-3">
                { subCaption }
              </h5>
            )}
            { ((href || to) && linkText) && (
              <Anchor
                id={`${linkText}-Link`}
                href={href}
                to={to}
                title={linkText}
                classNames="btn btn-primary btn-large fluid black white-text center-align pad-tb margin-tb"
                textStyles={{
                  bottom: '10px',
                  position: 'relative',
                }}
              />
          )}
          </Fragment>
        )
      }
    </div>
  </div>
);

export default memo(Slide);
