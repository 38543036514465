import React, { memo } from 'react';

const Avatar = ({
  classNames = 'circle responsive-img',
  alt = 'Avatar',
  title = 'Avatar',
  src = false,
  styles,
}) => (
  src && (
    <img
      className={classNames}
      alt={alt}
      title={title}
      src={src}
      style={styles}
    />
  )
);

export default memo(Avatar);
