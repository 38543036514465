import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ownProptypes from './proptypes';

import Text from './Text';

const Anchor = ({
  id,
  title,
  label,
  to,
  href,
  url,
  target,
  classNames,
  textClassNames,
  styles,
  textStyles,
  children,
  onClick,
}) => (
  (!href && !!to && to.length)
    ?
    <Link
      id={id}
      to={to}
      className={classNames}
      style={styles}
      title={title}
      target={target}
    >
      { children || (
        <Text title={title} label={label} classNames={textClassNames} styles={textStyles}>
          { title }
        </Text>
      )}
    </Link>
    :
    <a
      id={id}
      href={(url || href)}
      onClick={onClick}
      className={classNames}
      style={styles}
      title={title}
      target={target}
    >
      { children || (
        <Text title={title} label={label} classNames={textClassNames} styles={textStyles}>
          { title }
        </Text>
      )}
    </a>
);

Anchor.displayName = 'Anchor';

Anchor.propTypes = {
  id: PropTypes.string.isRequired,
  title: ownProptypes.typeCheck.string,
  label: ownProptypes.typeCheck.string,
  to: ownProptypes.typeCheck.string,
  href: ownProptypes.typeCheck.string,
  url: ownProptypes.typeCheck.string,
  target: ownProptypes.typeCheck.string,
  classNames: ownProptypes.typeCheck.string,
  styles: PropTypes.shape({}),
  textStyles: PropTypes.shape({}),
  children: ownProptypes.typeCheck.children,
};

Anchor.defaultProps = {
  target: '',
  title: '',
  styles: {},
  textStyles: {},
  children: false,
};

export default Anchor;
